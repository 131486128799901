import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { AccessTypeSelection } from '../../../../common/enums/access-type-selection.enum';
import { selectGranteeAccessType } from '../../../store/selectors/grantee.selector';
import { FlowKey } from '../../../services/flow-config-service/flow-config.service';

@Component({
    selector: 'zci-grantee-landing-page',
    templateUrl: './grantee-landing-page.component.html',
})
export class GranteeLandingPageComponent implements OnInit {
    // TODO If there is an error, redirect to error page

    accessLevel$: Observable<AccessTypeSelection> = this.store.select(selectGranteeAccessType);

    flowKey: FlowKey;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly store: Store,
    ) {}

    ngOnInit(): void {
        this.route.data
            .pipe(
                tap((data) => {
                    // TODO FIX THIS AGAIN
                    /* istanbul ignore next */
                    this.flowKey = data.validateToken.isExistingClient
                        ? 'EXISTING_GRANTEE_LIMITED'
                        : data.validateToken?.accessLevel === AccessTypeSelection.INFORMATION_ONLY
                          ? 'NEW_GRANTEE_INFO_ONLY'
                          : 'NEW_GRANTEE_LIMITED';
                }),
            )
            .subscribe();
    }
}
