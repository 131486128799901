import { ErrorPage } from '../../../services/generic-error/error-page-utils';
import { AdobeConfig } from '../../../utils/decorators/dev/adobe-launch/adobe-launch.interfaces';
import { AdobeMethods } from '../../../services/adobe-launch/adobe-launch.methods';
import { AnalyticsCtaType } from '../../../services/adobe-launch/adobe-launch.cta';

export const adobeConfigData: Record<ErrorPage, AdobeConfig> = {
    // TODO this is an example of tagging for different error pages
    //  each page's tags could be added here directly
    //  or made in separate files and imported to include in this array
    [ErrorPage.TECH_DIFF]: {
        ngOnInit: [
            {
                method: AdobeMethods.PAGE_LOAD,
                data: [''],
            },
        ],
        onPrimaryButtonClick: [
            {
                method: AdobeMethods.TRACK_ACTION,
                data: [
                    {
                        location: '',
                        ctaName: '',
                        ctaType: AnalyticsCtaType.BUTTON,
                    },
                ],
            },
        ],
        onSecondaryButtonClick: [
            {
                method: AdobeMethods.TRACK_ACTION,
                data: [
                    {
                        location: '',
                        ctaName: '',
                        ctaType: AnalyticsCtaType.BUTTON,
                    },
                ],
            },
        ],
    },
    [ErrorPage.GRANTEE_PIN_EXPIRED]: {},
    [ErrorPage.GRANTEE_CANT_COMPLETE_ONLINE]: {},
    [ErrorPage.GRANTEE_HAS_PENDING_REQUEST]: {},
    [ErrorPage.GRANTEE_TOO_MANY_ATTEMPTS]: {},
    [ErrorPage.GRANTEE_FOREIGN_ADDRESS]: {},
};
