export const modifiedGrantorContent = {
    stepperNavs: {
        navBack: 'Back',
        navContinue: 'Continue',
        navSubmit: 'Submit',
        navAgreeContinue: 'Agree and continue',
        navAddUserStepperViewSteps: 'View steps'
    },
    grantorStepperLabels: {
        labelText: 'Step ${currentStep} of ${totalSteps}',
        lblGranteeType: 'Grantee type',
        lblAccessLevel: 'Access level',
        lblPerson: 'Person',
        lblAgreement: 'Agreement',
        lblReviewSubmitStep: 'Review & submit'
    },
    granteeStepperLabels: {
        labelText: 'Step ${currentStep} of ${totalSteps}',
        lblUniquePin: 'Unique pin',
        lblRegulatoryQuestions: 'Regulatory questions',
        lblAuthorizationAgreement: 'Authorization agreement'
    },
    access_permissions: {
        heading: 'Access Permissions',
        navWhatAreAccountPermissions: 'What are access permissions?',
        navAccountPermissionsTabs: {
            navUsersTab: 'Users',
            navMyAccountsTab: 'My accounts',
            navAccountsAccessToTab: 'Accounts I have access to'
        },
        tblUsers: {
            lblTblColumnUser: 'User',
            lblTblColumnAccountName: 'Account name',
            lblTblColumnAccountType: 'Account type',
            lblTblColumnAccessType: 'Access type',
            lblAccountType: 'Account type',
            navCardView: 'Card View',
            navTableView: 'Table View',
            navUserActions: {
                labelText: 'Actions',
                navEditAccess: 'Edit access',
                navRemoveFromAccount: 'Remove from account',
                navNoAction: 'No action'
            },
            navLoadMoreUsers: 'Load ${userCount} more users'
        },
        tblMyAccounts: {
            lblTblColumnAccountName: 'Account name',
            lblTblColumnAccountType: 'Account type',
            lblTblColumnUser: 'User',
            lblAccountType: 'Account type',
            lblNoUsersAssigned: 'No users assigned',
            navCardView: 'Card View',
            navTableView: 'Table View',
            navMyAccountActions: {
                labelText: 'Actions',
                navAddUsers: 'Add users',
                navEditUsers: 'Edit users',
                navRemoveUsers: 'Remove users',
                navNoAction: 'No action'
            },
            navLoadMoreAccounts: 'Load ${accountCount} more accounts',
            navAccountUsers: '${usersCount} users',
            navAccountUser: '${usersCount} user'
        },
        tblAccountsIHaveAccess: {
            lblTblColumnAccountName: 'Account name',
            lblTblColumnAccountType: 'Account type',
            lblTblColumnUser: 'User',
            lblAccountType: 'Account type',
            navCardView: 'Card View',
            navTableView: 'Table View',
            navActionsAccountsAccessTo: {
                labelText: 'Actions',
                navRemoveAccess: 'Remove access',
                navNoAction: 'No action'
            }
        },
        pgcAccountHasUsers: {
            navBackToAllAccounts: 'Back to all accounts',
            tblAccountUsers: {
                lblTblColumnAccessType: 'Access type',
                lblTblColumnUser: 'User',
                navCardView: 'Card View',
                navTableView: 'Table View',
                navAccountUserActions: {
                    labelText: 'Actions',
                    navEditAccess: 'Edit access',
                    navRemoveAccess: 'Remove access',
                    navNoAction: 'No action'
                },
                navLoadMoreUsers: 'Load ${userCount} more users'
            }
        }
    },
    table_sort_labels: {
        lblSortAscending: 'Sorted ascending',
        lblSortDescending: 'Sorted descending',
        lblSortable: 'Sortable'
    },
    account_type_labels: {
        lbl403b7: 'Non-ERISA 403(b)(7)',
        lblAccount: 'Account',
        lblCharitableEndowment: 'Charitable endowment',
        lblCustodian: 'Custodian',
        lblEducationSavingAccount: 'Education savings account',
        lblEmployerPlan: 'Employer plan',
        lblEstate: 'Estate',
        lblGuardian: 'Guardian',
        lblIndividual: 'Individual',
        lblIndividual401k: 'Individual 401(k)',
        lblIndividualRoth401k: 'Individual Roth 401(k)',
        lblInheritedIra: 'Inherited IRA',
        lblInheritedRothIra: 'Inherited Roth IRA',
        lblJoint: 'Joint',
        lblKeogh: 'Keogh',
        lblMoneyPurchasePensionPlan: 'Money purchase pension plan',
        lblOther: 'Other',
        lblOutsideHoldings: 'Outside holdings',
        lblOutsideInvestment: 'Outside investment',
        lblProfitSharingPlan: 'Profit sharing plan',
        lblRolloverIra: 'Rollover IRA',
        lblRothConversionIra: 'Roth conversion IRA',
        lblRothIra: 'Roth IRA',
        lblSepIra: 'SEP IRA',
        lblSimpleIra: 'Simple IRA',
        lblTraditionalIra: 'Traditional IRA',
        lblTrust: 'Trust',
        lblUgma: 'UGMA',
        lblUnknown: 'Unknown',
        lblUtma: 'UTMA'
    },
    access_comparison_table: {
        heading: 'About account access',
        navBack: 'Back',
        tblAccessComparison: {
            cpyAccessComparison: 'Access comparison',
            lblAuthorization: 'Authorization',
            lblInformationAccess: 'Information access',
            lblLimitedAuthority: 'Limited authority',
            lblFullAuthority: 'Full authority',
            lblAccessComparisonObtainAccount: {
                labelText: 'Obtain account information online or by phone',
                lblObtainAccount: 'Obtain account information online or by phone',
                lblInfoAccessActionAllowed: 'true',
                lblLimitedAccessActionAllowed: 'true',
                lblFullAuthorityActionAllowed: 'true'
            },
            lblAccessComparisonBuySellExchange: {
                labelText: 'Buy, sell, and exchange Vanguard mutual funds and trade stocks, bonds, and other securities',
                lblBuySellExchange: 'Buy, sell, and exchange Vanguard mutual funds and trade stocks, bonds, and other securities',
                lblInfoAccessActionNotAllowed: 'false',
                lblLimitedAccessActionAllowed: 'true',
                lblFullAuthorityActionAllowed: 'true'
            },
            lblAccessComparisonYouOrBank: {
                labelText: 'Sell assets and have the proceeds sent to you or the bank account on record',
                lblSellAssetsProceedsYouOrBank: 'Sell assets and have the proceeds sent to you or the bank account on record',
                lblInfoAccessActionNotAllowed: 'false',
                lblLimitedAccessActionAllowed: 'true',
                lblFullAuthorityActionAllowed: 'true'
            },
            lblAccessComparisonWriteChecks: {
                labelText: 'Write checks from your account',
                lblWriteChecks: 'Write checks from your account',
                lblInfoAccessActionNotAllowed: 'false',
                lblLimitedAccessActionNotAllowed: 'false',
                lblFullAuthorityActionAllowed: 'true'
            },
            lblAccessComparisonChangeOwnership: {
                labelText: 'Change ownership of your account',
                lblChangeOwnership: 'Change ownership of your account',
                lblInfoAccessActionNotAllowed: 'false',
                lblLimitedAccessActionNotAllowed: 'false',
                lblFullAuthorityActionAllowed: 'true'
            },
            lblAccessComparisonChangeAccount: {
                labelText: 'Change account beneficiaries',
                lblChangeAccount: 'Change account beneficiaries',
                lblInfoAccessActionNotAllowed: 'false',
                lblLimitedAccessActionNotAllowed: 'false',
                lblFullAuthorityActionAllowed: 'true'
            },
            lblAccessComparisonChangeBankInfo: {
                labelText: 'Change bank information on the account',
                lblChangeBankInfo: 'Change bank information on the account',
                lblInfoAccessActionNotAllowed: 'false',
                lblLimitedAccessActionNotAllowed: 'false',
                lblFullAuthorityActionAllowed: 'true'
            },
            lblAccessComparisonThirdParty: {
                labelText: 'Sell assets and have the proceeds made payable to a third party or mailed to a new address',
                lblSellAssetsProceedsThirdParty: 'Sell assets and have the proceeds made payable to a third party or mailed to a new address',
                lblInfoAccessActionNotAllowed: 'false',
                lblLimitedAccessActionNotAllowed: 'false',
                lblFullAuthorityActionAllowed: 'true'
            }
        },
        tblEligibleAccounts: {
            cpyEligibleAccounts: 'Eligible accounts',
            lblAuthorization: 'Authorization',
            lblInformationAccess: 'Information access',
            lblLimitedAuthority: 'Limited authority',
            lblFullAuthority: 'Full authority',
            lblEligibleAccountIndividualNonRetirement: {
                labelText: 'Individual nonretirement',
                lblIndividualNonRetirement: 'Individual nonretirement',
                lblInfoAccessActionAllowed: 'true',
                lblLimitedAccessActionAllowed: 'true',
                lblFullAuthorityActionAllowed: 'true'
            },
            lblEligibleAccountJoint: {
                labelText: 'Joint',
                lblJoint: 'Joint',
                lblInfoAccessActionAllowed: 'true',
                lblLimitedAccessActionAllowed: 'true',
                lblFullAuthorityActionAllowed: 'true'
            },
            lblEligibleAccountIra: {
                labelText: 'IRA',
                lblIra: 'IRA',
                lblInfoAccessActionAllowed: 'true',
                lblLimitedAccessActionAllowed: 'true',
                lblFullAuthorityActionAllowed: 'true'
            },
            lblEligibleAccountNonErisa: {
                labelText: 'Non-ERISA 403(b)(7)',
                lblNonErisa: 'Non-ERISA 403(b)(7)',
                lblInfoAccessActionAllowed: 'true',
                lblLimitedAccessActionAllowed: 'true',
                lblFullAuthorityActionAllowed: 'true'
            },
            lblEligibleAccountIndividual401k: {
                labelText: 'Individual 401(k)',
                lblIndividual401k: 'Individual 401(k)',
                lblInfoAccessActionAllowed: 'true',
                lblLimitedAccessActionAllowed: 'true',
                lblFullAuthorityActionAllowed: 'true'
            },
            lblEligibleAccountOrganization: {
                labelText: 'Organization/corporate',
                lblOrganization: 'Organization/corporate',
                lblInfoAccessActionAllowed: 'true',
                lblLimitedAccessActionAllowed: 'true',
                lblFullAuthorityActionNotAllowed: 'false'
            },
            lblEligibleAccountTrust: {
                labelText: 'Trust',
                lblTrust: 'Trust',
                lblInfoAccessActionAllowed: 'true',
                lblLimitedAccessActionAllowed: 'true',
                lblFullAuthorityActionNotAllowed: 'false'
            },
            lblEligibleAccountForMinors: {
                labelText: 'UGMA/UTMA, custodial, or other education savings',
                lblAccountsForMinors: 'UGMA/UTMA, custodial, or other education savings',
                lblInfoAccessActionAllowed: 'true',
                lblLimitedAccessActionAllowed: 'true',
                lblFullAuthorityActionNotAllowed: 'false'
            },
            lblEligibleAccountVripProgram: {
                labelText: 'Vanguard Retirement Investment Program',
                lblVripProgram: 'Vanguard Retirement Investment Program',
                lblInfoAccessActionAllowed: 'true',
                lblLimitedAccessActionAllowed: 'true',
                lblFullAuthorityActionNotAllowed: 'false'
            }
        }
    },
    add_user: {
        navContactUs: {
            labelText: 'Contact us',
            hlpContactUs: {
                heading: 'Contact us',
                cnfoWealthManagementSupportTeam: {
                    text1: 'Wealth Management support team',
                    text2: 'vanguard@fs.vanguard.com',
                    text3: '800-345-1344',
                    text4: 'Wealth Management support phone ext.',
                    text5: 'M-F, 8 a.m. to 6 p.m., ET to manage all your account access requests.'
                }
            }
        },
        navQuit: 'Quit',
        navAddUserStepperViewSteps: 'View steps',
        lblAddUserSteps: {
            labelText: 'Step ${currentStep} of ${totalSteps}',
            lblGranteeType: 'Grantee type',
            lblAccessLevel: 'Access level',
            lblPerson: 'Person',
            lblAgreement: 'Agreement',
            lblReviewSubmitStep: 'Review & submit'
        },
        lblGiveAccess: 'Give access',
        wrnQuitUnsaved: {
            heading: 'You have unsaved changes. Are you sure you want to quit?',
            navYes: 'Yes',
            navNo: 'No'
        },
        frmcWhoGivingAccess: {
            heading: 'Who are you giving access to your account?',
            frmfIndividualAlreadyHasPermission: 'An individual that I’ve already given access permissions to another one of my accounts',
            frmfIndividualHasOwnVanguardAccount: 'An individual who has their own Vanguard account (an existing Vanguard client)',
            frmfIndividualWithoutVanguardAccount: {
                heading: 'An individual without a Vanguard account',
                body: '<p>We will take you to our form.</p>\n'
            },
            frmfOrganizationTrust: {
                heading: 'An entire organization or trust',
                body: '<p>We will take you to our form.</p>\n'
            },
            navBack: 'Back'
        },
        frmcSelectUserToGiveAccess: {
            heading: 'Select the person to give access to',
            navContinue: 'Continue',
            navBack: 'Back',
            navAddSomeoneElse: 'Add someone else',
            errPleaseChooseAPerson: 'Please choose a person from the list or add someone else',
            wrnSelectUserRequiresForm: {
                heading: 'Giving access to this person requires a form. Select continue to go to the form.',
                navContinue: 'Continue',
                navBack: 'Back'
            },
            hlpAssociatedControlPersonAccess: {
                navCompleteOurForm: 'complete our form',
                text1: '<p><b>Note:</b><br>\n' +
                    'If you are giving access to an associated or control person, ${complete-our-form} instead.</p>\n'
            },
            frmcAddPerson: {
                heading: 'Tell us about this person',
                body: '<p>Enter the information of the person you are giving access to.</p>\n',
                frmfFirstName: {
                    labelText: 'First Name',
                    errFirstNameRequired: 'Please enter their first name',
                    errNameValidCharacters: "Please enter only letters, numbers, or special characters - . ' &"
                },
                frmfLastName: {
                    labelText: 'Last Name',
                    errLastNameRequired: 'Please enter their last name',
                    errNameValidCharacters: "Please enter only letters, numbers, or special characters - . ' &"
                },
                frmfEmailAddress: {
                    labelText: 'Email address',
                    errEmailAddressRequired: 'Please enter their email address',
                    errValidEmail: 'Enter a valid email address'
                },
                frmfPhoneNumber: {
                    labelText: 'Phone number',
                    errPhoneNumberRequired: 'Please enter their phone number',
                    errValidPhone: 'Enter a valid phone number'
                },
                navContinue: 'Continue',
                navBack: 'Back',
                errAddNewUserAlreadyExists: 'This person already exists in your access permissions. Select <b>back</b> to choose this person.',
                errAddNewUserAlreadyHasAccess: 'This person already has access to this account. Please enter the information of a new person.',
                wrnAddNewUserHasForeignAddress: {
                    text1: 'For giving access to someone with a foreign address,${please-use-our-form}',
                    navUseOurForm: 'please use our form'
                },
                errAddNewUserInfoDoesNotMatch: 'This information provided does not match our records. For your security and theirs, please verify the information with the person you are adding before trying again.',
                errIneligibleScenarios: {
                    errZeroBalanceAccess: {
                        heading: "You can't give someone access to a zero balance account",
                        text1: 'Try again after funding the account or select a different account.',
                        navReturnToAccessPermissions: 'Return to access permissions'
                    },
                    errIneligibleAddResetAccess: {
                        heading: 'You are ineligible to add someone right now',
                        text1: 'For your security, we don’t allow you to give new access when you have set up or reset your online access within the last seven days. Try again later.',
                        navReturnToAccessPermissions: 'Return to access permissions'
                    },
                    errIneligibleAddAddressChanged: {
                        heading: 'You are ineligible to add someone right now',
                        text1: 'For your security, we don’t allow you to give new access when you have changed your address within the last seven days. Try again later.',
                        navReturnToAccessPermissions: 'Return to access permissions'
                    },
                    infmOrgTrustRequiresForm: {
                        heading: "Let's take you to our form",
                        text1: 'Giving someone access to an organization or trust account requires our form. Select <b>continue</b> to open the form.',
                        navContinue: 'Continue',
                        navReturnToAccessPermissions: 'Return to access permissions'
                    },
                    errAccessPledgedAccount: {
                        heading: "You can't give access to a pledged account",
                        text1: 'Try again with a different account.',
                        navReturnToAccessPermissions: 'Return to access permissions'
                    },
                    errIneligibleAddNonresident: {
                        heading: 'You are ineligible to add someone',
                        text1: 'You are not able to give access to this account because either you or another owner of the account is a nonresident alien.',
                        navReturnToAccessPermissions: 'Return to access permissions'
                    },
                    errNoTinMatch: {
                        heading: 'Give us a call',
                        text1: '<p>The taxpayer identification number (TIN) on your Vanguard account doesn’t match IRS records.</p>\n' +
                            "<p>Call us at 877-662-7447 to resolve before giving access. We're available M-F, 8 a.m. to 8 p.m., ET.</p>\n" +
                            '<p>For individuals with speech or hearing limitations, please utilize relay service, video relay service, and/or 711 to call us.</p>\n',
                        navReturnToAccessPermissions: 'Return to access permissions'
                    }
                },
                errPendingAccessRequest: {
                    heading: 'This person already has a pending access request on this account.  ',
                    text1: '<p>Either remind them to accept the request by resending your unique pin, or enter the details of someone new.<br>\n' +
                        '</p>\n'
                }
            }
        },
        frmcSelectAccessLevel: {
            heading: 'Choose an access level to add',
            frmfInformationOnly: {
                labelText: 'Information only',
                body: '<ul>\n<li>Obtain account information online or by phone.</li>\n</ul>\n',
                lblOnlineCompletion: 'Online completion'
            },
            frmfLimitedAccess: {
                labelText: 'Limited authority',
                body: '<ul>\n' +
                    '<li>Obtain account information online or by phone.</li>\n' +
                    '<li>Buy, sell, exchange and trade within your account.</li>\n' +
                    '<li>Send investment proceeds to you or the bank account on record.</li>\n' +
                    '</ul>\n',
                lblOnlineCompletion: 'Online completion'
            },
            frmfFullAuthority: {
                labelText: 'Full authority',
                body: '<ul>\n' +
                    '<li>Obtain account information online or by phone.</li>\n' +
                    '<li>Buy, sell, exchange and trade within your account.</li>\n' +
                    '<li>Send investment proceeds to anyone.</li>\n' +
                    '<li>Open new accounts and sign your tax forms.</li>\n' +
                    '<li>Write checks from your account.</li>\n' +
                    '<li>Change account ownership, beneficiaries, bank information, and addresses.</li>\n' +
                    '</ul>\n',
                lblFormCompletion: 'Form completion',
                lblNotaryRequired: 'Notary required'
            },
            navBack: 'Back',
            errSelectAccessLevel: 'Please select an access level',
            wrnSelectAccessOnlineTrasactionsNotAvailable: {
                heading: 'Note: Online transactions are not available for advised accounts',
                text1: '<p>You have selected to give ${accessLevelAccess} authority to an advised account. For future reference,&nbsp;the person you are giving access to will need to call Vanguard to transact.</p>\n',
                navContinue: 'Continue',
                navBack: 'Back'
            },
            wrnInfoOnlyAccNumberVisible: {
                heading: 'Your routing and account number will be visible',
                text1: '<p>Giving access to a Vanguard Cash Plus Account shows both your routing and account numbers. This makes it possible to transact on third party websites. Monitor your account to ensure this information is used appropriately.</p>\n',
                navContinue: 'Continue',
                navBack: 'Back'
            }
        },
        frmcTrmAgreement: {
            heading: 'Authorization agreement',
            navAgreeContinue: 'Agree and continue',
            navBack: 'Back',
            frmcTrmAgreementLimitedAccess: "<p>IMPORTANT: You are granting another person (the &quot;grantee&quot;) authority to act on your accounts. By signing below, you authorize the grantee's limited authority to act on the Vanguard mutual fund and Vanguard Brokerage Accounts you have designated in accordance with the terms below and any additional terms that Vanguard requires to affect the authorization.</p>\n" +
                '<p><b>Authorization by the account owner</b></p>\n' +
                "<p>I acknowledge that the authority I am giving the grantee will result in the grantee's authority to perform the following transactions by mail, phone, and online on the account(s) I have designated, subject to Vanguard policies and procedures:</p>\n" +
                '<ol>\n' +
                '<li>Purchase, exchange, and sell Vanguard mutual funds within my Vanguard accounts.</li>\n' +
                '<li>Purchase, sell (including short sales), and trade in stocks, bonds, and other securities and contracts relating to the same.</li>\n' +
                '<li>Engage in margin and/or option trading if established by me.</li>\n' +
                '<li>Transfer or invest assets between Vanguard accounts owned solely by me, including my revocable trust if I am the sole trustee.</li>\n' +
                '<li>Request that distributions from my accounts be payable to me and sent to my&nbsp;bank account or address on&nbsp;record.</li>\n' +
                '<li>Obtain information on my Vanguard mutual fund and Vanguard Brokerage Account(s), including historical account information.</li>\n' +
                '</ol>\n' +
                '<p>I understand that the authority granted herein is limited to the level of authority specified above. The grantee won’t have authority to take any other action, including, but not limited to:</p>\n' +
                '<ol>\n' +
                '<li>Change the address on&nbsp;record or bank information on my accounts.</li>\n' +
                '<li>Transfer or invest assets into a different registration.</li>\n' +
                '<li>Redeem assets by phone, online, or by wire transfer to anyone other than me or my address on record.</li>\n' +
                '<li>Use checkwriting privileges on my accounts.</li>\n' +
                '<li>Add, change, or delete beneficiary designations.</li>\n' +
                '<li>Sign a new account application or otherwise open a new registration on my behalf.</li>\n' +
                '<li>Sign any tax information or reporting form required by federal or state taxing authorities, including IRS Form W-9 and any substitute IRS Form W-9.</li>\n' +
                '<li>Request a distribution from a Vanguard Brokerage plan account.</li>\n' +
                '<li>Appoint another agent to act on&nbsp;my behalf or register on&nbsp;my behalf or change&nbsp;my online access through vanguard.com.</li>\n' +
                '<li>Consent to and contract with any advice service offered by Vanguard, including, but not limited to, Vanguard Personal Advisor Services®.</li>\n' +
                '</ol>\n' +
                '<p>I understand that this Limited Power of Attorney shall be governed and construed in accordance with the laws of the Commonwealth of Pennsylvania, without reference to principles of conflicts of laws, and for such purposes (1) shall be deemed to constitute a power contained in an instrument used in a commercial transaction that authorizes an agency relationship granted exclusively to facilitate the transfer of stock, bonds, and other assets; (2) is durable; (3) may be exercised independently of any other agent I have previously or in the future may appoint or designate unless revoked; (4) is subject to the duties and limitations on liability and disclosure obligations provided by 20 PA.C.S. § 5601.3; and (5) includes, but is not limited to, the power to engage in stock, bond, and other securities transactions as specified by 20 PA.C.S. § 5603.</p>\n' +
                '<p>I agree that neither The Vanguard Group, Inc., Vanguard Fiduciary Trust Company, Vanguard Marketing Corporation, nor any of their respective affiliates are responsible for monitoring whether my agent is an Investment Adviser (as defined in Section 202(a)(11) of the U.S. Investment Advisers Act) or properly registered as an Investment Adviser with the SEC or state(s). I understand that none of these entities, nor their respective affiliates, perform any services for Investment Advisers to satisfy their custody rule requirements 17 CFR § 275.206(4)-2.</p>\n' +
                '<p>I agree that none of The Vanguard Group, Inc., Vanguard Fiduciary Trust Company, Vanguard Marketing Corporation, or any of their respective affiliates will be held responsible for my decisions or for the investment recommendations or decisions of my agent(s), and is under no duty whatsoever to question any instructions received from the agent(s) or the suitability of any transactions requested by him/her/them. </p>\n' +
                '<p>I agree to indemnify and hold harmless The Vanguard Group, Inc., Vanguard Fiduciary Trust Company, Vanguard Marketing Corporation, their affiliates, and each of the investment company members of The Vanguard Group and their respective officers, employees, and agents (collectively, Vanguard) from acting on instructions—whether oral, written, or online—reasonably believed by Vanguard to have originated from my agent(s), and from all acts of my agent(s) involving the Vanguard mutual fund accounts or Vanguard Brokerage Accounts covered by this authorization.</p>\n' +
                '<p>In the event of my disability or incapacity, this authorization will remain in full force and effect. This authorization will terminate only when one of the following occurs:</p>\n' +
                '<ul>\n' +
                '<li>Vanguard receives notice of my revocation. I understand that I have the right to revoke this authorization at any time and, if I have named multiple agents, I understand that I must specifically identify the agent(s) whose authority I am revoking. I further understand that each authorization covering the same accounts and the same level of authority (limited or full agents) submitted to Vanguard will revoke any prior authorization only to the extent indicated in such subsequent authorization. Any revocation will not affect any liability resulting from transactions initiated before Vanguard has had a reasonable amount of time to act upon such notice.</li>\n' +
                '<li>Vanguard receives notice of my death.&nbsp;I understand that the authority granted under this authorization terminates at my death. My death will not affect any liability resulting from transactions initiated before Vanguard has had a reasonable amount of time to act upon such notice.</li>\n' +
                '</ul>\n' +
                '<p>I understand that Vanguard reserves the right to reject, at any time, instructions that Vanguard believes to be suspicious, fraudulent or otherwise unauthorized, or illegal.<br>\n' +
                '<br>\n' +
                'I have read this authorization in its entirety or had it explained to me, and I understand its contents.<br>\n' +
                '<br>\n' +
                'If I am signing as trustee, I confirm that I have the authority to appoint one or more agents and to delegate authority under the trust document and/or applicable law.<br>\n' +
                '<br>\n' +
                'I understand that this request will be canceled, and no grant of authority will be effected, if all necessary actions by the grantee have not been completed or provided to Vanguard in good order within 14 days after the business day on which my request is deemed submitted.<br>\n' +
                '<br>\n' +
                'I understand that clicking <b>agree and continue</b>, I am authorizing the grant of limited authority on the designated Vanguard account(s), all under the terms described above.</p>\n',
            frmcTrmAgreementInformationOnly: '<p><b>IMPORTANT:</b> You are authorizing another person (the &quot;grantee&quot;) to access information only on your account(s). By signing below, you authorize the grantee’s information access to those Vanguard mutual fund and Vanguard Brokerage Account(s) you have designated, in accordance with the terms below.</p>\n' +
                '<ol>\n' +
                '<li>I authorize the grantee online and phone access to information, including historical account information, for the Vanguard mutual fund and Vanguard Brokerage Account(s) I have designated.</li>\n' +
                '<li>I acknowledge that the grantee I have identified is not authorized to enter into or request any transaction on any of my accounts nor to add, delete, or change any account option.</li>\n' +
                '<li>I understand that I can revoke this authorization at any time.</li>\n' +
                '<li>I acknowledge that the authority I have granted to the grantee shall remain in effect until Vanguard receives notice of my revocation or my death and Vanguard has had a reasonable amount of time to act upon such notice.</li>\n' +
                '<li>I understand and agree that this request is my responsibility and at my risk. I agree to indemnify and hold The Vanguard Group, Inc., Vanguard Fiduciary Trust Company, Vanguard Marketing Corporation, each of the investment company members of The Vanguard Group, and their respective affiliates, employees, agents, and officers, harmless from any liability resulting from providing to the grantee any information I have authorized in this request.</li>\n' +
                '</ol>\n' +
                '<p>I understand that clicking <b>agree and continue</b>, I am authorizing the grantee to access information on the accounts I have designated, all under the terms described above.</p>\n'
        },
        frmcAddUserReviewSubmit: {
            heading: 'Review and submit',
            body: '<p>${userName} is gaining ${accessLevelAccess} to this account:</p>\n',
            navSubmit: 'Submit',
            navBack: 'Back',
            errTooManyUnsuccessfulAttempts: {
                heading: 'Too many unsuccessful attempts',
                text1: "<p>In order to protect our client's information, we are stopping any additional attempts.</p>\n" +
                    '<p>In order to gain access to this account, the person granting it must repeat the authorization process.</p>\n',
                navReturnToAccessPermissions: 'Return to access permissions'
            },
            scssInformationAccessRequestSubmitted: {
                text1: '<p>${fullName} now has information only access to your account</p>\n',
                navAddAnotherPerson: 'Add another person',
                navGoToMyAccount: 'Go to my account'
            },
            lstNewGranteeNotOnListSuccessMessage: {
                heading: "What's next?",
                listiWhatsNextUniquePin: {
                    heading: 'Give this unique pin to ${username}.',
                    text1: '<p>We sent a copy of the pin to your secure message center. This pin is <b>case sensitive</b>.</p>\n',
                    primaryCta: 'Copy pin'
                },
                listiWhatsNextEmailLink: '<p>We will email ${userName} a link to accept your request using the pin you give them. The request and pin will expire in 14 days.</p>\n',
                listiWhatsNextAccessToAccount: '<p>Once ${username} accepts, they will have access to your account. Both of your <b>access permissions</b> will reflect the changes.</p>\n',
                navAddAnotherPerson: 'Add another person',
                navReturnToAccessPermissions: 'Return to access permissions'
            },
            lstExistingLimitedSuccessMessage: {
                heading: 'What happens next?',
                listiWhatHappensNextSendMessage: '<p>We will send ${userFullName} a message with your limited authority request. We recommend you also let ${secondUserName} know you’re giving them access to your account.</p>\n',
                listiWhatHappensNextEsign: '<p>${userFullName} must e-sign to accept your request on vanguard.com within 14 days, or the request will be canceled.</p>\n',
                listiWhatHappensNextAccessAccount: {
                    text1: '<p>Once ${userFullName} e-signs, they will have access to your account. The changes will be reflected in both your and their ${nav-access-permissions}.<br>\n' +
                        '</p>\n',
                    primaryCta: 'access permissions'
                },
                navAddAnotherPerson: 'Add another person',
                navGoToMyAccount: 'Go to my account',
                scssLimitedAuthoritySubmitted: 'Your limited authority request has been submitted.'
            },
            hlpJointAccountAccess: 'Note:If ${firstName} wants to act on behalf of the other owner of your joint account, that owner must also add ${firstName} to the account in their own access permissions.'
        },
        errTechnicalDifficultiesCall: {
            heading: 'Give us a call',
            text1: '<p>We are experiencing technical difficulties.</p>\n' +
                '<p>For immediate help, contact your Relationship Manager or call 877-345-1344. We’re available Monday through Friday 8 a.m. to 8 p.m., Eastern time.</p>\n' +
                '<p>For individuals with speech or hearing limitations, please utilize relay service, video relay service, and/or 711 to call us.</p>\n',
            navReturnProfileAccountSettings: 'Return to profile & account settings'
        },
        errGranteeAlreadyPendingRequest: {
            heading: 'This person has a pending request',
            text1: '<p>We are not able to complete a request while one is already pending. Once we complete the pending request, you may submit another.</p>\n'
        }
    },
    remove_user: {
        navContactUs: {
            labelText: 'Contact us',
            hlpContactUs: {
                heading: 'Contact us',
                cnfoWealthManagementSupportTeam: {
                    lblWmSupportName: 'Wealth Management support team',
                    lblWmSupportEmail: 'vanguard@fs.vanguard.com',
                    lblWmSupportPhone: '800-345-1344',
                    lblWmSupportPhoneExt: 'Wealth Management support phone ext.',
                    lblWmSupportHours: 'M-F, 8 a.m. to 6 p.m., ET to manage all your account access requests.'
                }
            }
        },
        navQuit: 'Quit',
        frmcRemoveAccessSelectUser: {
            heading: 'Whose access would you like to remove?',
            lblSelectUser: 'Select user',
            navNext: 'Next',
            navBack: 'Back'
        },
        frmcRemoveUserReviewSubmit: {
            heading: 'Review and submit',
            lblReviewSubmit: 'Review and submit',
            navNext: 'Next',
            navBack: 'Back',
            scssUserWasRemoved: {
                heading: '${granteeName} was removed',
                navReturnToAccessPermissions: 'Return to access permissions'
            }
        }
    },
    remove_account_access: {
        heading: 'Contact your ${contactPersonType}',
        cnfoWealthManagementSupportTeam: {
            lblWmSupportName: 'Wealth Management support team',
            lblWmSupportEmail: 'vanguard@fs.vanguard.com',
            lblWmSupportPhone: '800-345-1344',
            lblWmSupportPhoneExt: 'Wealth Management support phone ext.',
            lblWmSupportHours: 'M-F, 8 a.m. to 6 p.m., ET to manage all your account access requests.'
        }
    },
    add_user_access: {
        heading: 'To give access to an existing Vanguard client use this ${online process}',
        navOnlineProcess: 'online process',
        cnfoWealthManagementSupportTeam: {
            lblWmSupportName: 'Wealth Management support team',
            lblWmSupportEmail: 'vanguard@fs.vanguard.com',
            lblWmSupportPhone: '800-345-1344',
            lblWmSupportPhoneExt: 'Wealth Management support phone ext.',
            lblWmSupportHours: 'M-F, 8 a.m. to 6 p.m., ET to manage all your account access requests.'
        },
        cpyQuestionsOnAccessType: {
            heading: 'Have questions about access types or eligible accounts?',
            navAccessComparisonTable: 'access comparison table'
        }
    },
    edit_remove_user_access: {
        heading: 'Contact your ${contactPersonType}',
        cnfoWealthManagementSupportTeam: {
            lblWmSupportName: 'Wealth Management support team',
            lblWmSupportEmail: 'vanguard@fs.vanguard.com',
            lblWmSupportPhone: '800-345-1344',
            lblWmSupportPhoneExt: 'Wealth Management support phone ext.',
            lblWmSupportHours: 'M-F, 8 a.m. to 6 p.m., ET to manage all your account access requests.'
        },
        cpyQuestionsOnAccessType: {
            heading: 'Have questions about access types or eligible accounts?',
            navAccessComparisonTable: 'access comparison table'
        }
    },
    role_types: {
        lblRoleAspt: {
            keyCode: 'ASPT',
            labelValue: 'IIG authorized signer'
        },
        lblRoleBeal: {
            keyCode: 'BEAL',
            labelValue: 'Benefactor alias'
        },
        lblRoleBebe: {
            keyCode: 'BEBE',
            labelValue: 'Benefactor benefactor'
        },
        lblRoleBn: {
            keyCode: 'BN',
            labelValue: 'Beneficiary'
        },
        lblRoleBnbn: {
            keyCode: 'BNBN',
            labelValue: 'Beneficiary beneficiary'
        },
        lblRoleCual: {
            keyCode: 'CUAL',
            labelValue: 'Custodian alias'
        },
        lblRoleCuco: {
            keyCode: 'CUCO',
            labelValue: 'Custodian conservator'
        },
        lblRoleCucu: {
            keyCode: 'CUCU',
            labelValue: 'Custodian custodian'
        },
        lblRoleCuea: {
            keyCode: 'CUEA',
            labelValue: 'Custodian escrow agent'
        },
        lblRoleCugu: {
            keyCode: 'CUGU',
            labelValue: 'Custodian guardian'
        },
        lblRoleCult: {
            keyCode: 'CULT',
            labelValue: 'Custodian life tenant'
        },
        lblRoleCunm: {
            keyCode: 'CUNM',
            labelValue: 'Custodian nominee firm'
        },
        lblRoleCupa: {
            keyCode: 'CUPA',
            labelValue: 'Custodian Attorney-in-Fact'
        },
        lblRoleCupl: {
            keyCode: 'CUPL',
            labelValue: 'Custodian pledgee'
        },
        lblRoleCusd: {
            keyCode: 'CUSD',
            labelValue: 'Custodian'
        },
        lblRoleCuus: {
            keyCode: 'CUUS',
            labelValue: 'Custodian usufruct'
        },
        lblRoleExad: {
            keyCode: 'EXAD',
            labelValue: 'Executor administrator'
        },
        lblRoleExex: {
            keyCode: 'EXEX',
            labelValue: 'Executor executor'
        },
        lblRoleGafs: {
            keyCode: 'GASF',
            labelValue: 'Auth signer full agent - Org Res'
        },
        lblRoleGasd: {
            keyCode: 'GASD',
            labelValue: 'Auth signer durable lmtd agent - Org Res'
        },
        lblRoleGasl: {
            keyCode: 'GASL',
            labelValue: 'Auth signer limited agent - Org Res'
        },
        lblRoleGasr: {
            keyCode: 'GASR',
            labelValue: 'Granted authorized signer - Org Res'
        },
        lblRoleGdla: {
            keyCode: 'GDLA',
            labelValue: 'Granted durable limited authorization'
        },
        lblRoleGfaa: {
            keyCode: 'GFAA',
            labelValue: 'Granted full agent authorization'
        },
        lblRoleGfoa: {
            keyCode: 'GFOA',
            labelValue: 'Granted family office agent'
        },
        lblRoleGipr: {
            keyCode: 'GIPR',
            labelValue: 'Granted interested party'
        },
        lblRoleGlaa: {
            keyCode: 'GLAA',
            labelValue: 'Granted limited agent authorization'
        },
        lblRoleGlas: {
            keyCode: 'GLAS',
            labelValue: 'Granted limited authorized signer - Org Res'
        },
        lblRoleIphr: {
            keyCode: 'IPHR',
            labelValue: 'Interested party hard recipient'
        },
        lblRoleIpir: {
            keyCode: 'IPIR',
            labelValue: 'Interested party secondary recipient'
        },
        lblRoleIppv: {
            keyCode: 'IPPV',
            labelValue: 'IIG interested party'
        },
        lblRoleNpb1: {
            keyCode: 'NPB1',
            labelValue: '403B external participant'
        },
        lblRoleNpb2: {
            keyCode: 'NPB2',
            labelValue: '403B external participant'
        },
        lblRoleNppt: {
            keyCode: 'NPPT',
            labelValue: '403B external participant'
        },
        lblRoleNpq1: {
            keyCode: 'NPQ1',
            labelValue: '403B external participant'
        },
        lblRoleNpq2: {
            keyCode: 'NPQ2',
            labelValue: '403B external participant'
        },
        lblRoleOw: {
            keyCode: 'OW',
            labelValue: 'Owner'
        },
        lblRoleOwtr: {
            keyCode: 'OWTR',
            labelValue: 'Owner'
        },
        lblRoleSgac: {
            keyCode: 'SGAC',
            labelValue: 'Accountant'
        },
        lblRoleSgam: {
            keyCode: 'SGAM',
            labelValue: 'Account mailing address'
        },
        lblRoleSgao: {
            keyCode: 'SGAO',
            labelValue: 'Account officer'
        },
        lblRoleSgbc: {
            keyCode: 'SGBC',
            labelValue: 'Business/Corporation'
        },
        lblRoleSgcb: {
            keyCode: 'SGCB',
            labelValue: 'Contingent beneficiary'
        },
        lblRoleSgcf: {
            keyCode: 'SGCF',
            labelValue: 'CO-Fiduciary'
        },
        lblRoleSgfp: {
            keyCode: 'SGFP',
            labelValue: 'Financial plan recipient'
        },
        lblRoleSgib: {
            keyCode: 'SGIB',
            labelValue: 'Income beneficiary'
        },
        lblRoleSgio: {
            keyCode: 'SGIO',
            labelValue: 'Investment officer'
        },
        lblRoleSgip: {
            keyCode: 'SGIP',
            labelValue: 'INCOME/PRIN beneficiary'
        },
        lblRoleSgot: {
            keyCode: 'SGOT',
            labelValue: 'Other type'
        },
        lblRoleSgpb: {
            keyCode: 'SGPB',
            labelValue: 'Principal beneficiary'
        },
        lblRoleSgqr: {
            keyCode: 'SGQR',
            labelValue: 'Quarterly report recipient'
        },
        lblRoleSgts: {
            keyCode: 'SGTS',
            labelValue: 'Sungard secondary owner'
        },
        lblRoleSgtt: {
            keyCode: 'SGTT',
            labelValue: 'Sungard primary owner'
        },
        lblRoleShal: {
            keyCode: 'SHAL',
            labelValue: 'Shareholder alias'
        },
        lblRoleShbn: {
            keyCode: 'SHBN',
            labelValue: 'Shareholder beneficiary'
        },
        lblRoleShgp: {
            keyCode: 'SHGP',
            labelValue: 'Shareholder general partner'
        },
        lblRoleShjs: {
            keyCode: 'SHJS',
            labelValue: 'Shareholder joint shareholder'
        },
        lblRoleShlp: {
            keyCode: 'SHLP',
            labelValue: 'Shareholder limited partner'
        },
        lblRoleShmn: {
            keyCode: 'SHMN',
            labelValue: 'Shareholder minor'
        },
        lblRoleShno: {
            keyCode: 'SHNO',
            labelValue: 'Shareholder naked owner'
        },
        lblRoleShrm: {
            keyCode: 'SHRM',
            labelValue: 'Shareholder remainderman'
        },
        lblRoleShsh: {
            keyCode: 'SHSH',
            labelValue: 'Shareholder shareholder'
        },
        lblRoleSx02: {
            keyCode: 'SX02',
            labelValue: 'Fee statement'
        },
        lblRoleSx40: {
            keyCode: 'SX40',
            labelValue: '5498 payee'
        },
        lblRoleSx70: {
            keyCode: 'SX70',
            labelValue: 'Tax beneficiary'
        },
        lblRoleSx80: {
            keyCode: 'SX80',
            labelValue: 'Rpt Pkg Recipient'
        },
        lblRoleSx90: {
            keyCode: 'SX90',
            labelValue: 'Trust check recipient'
        },
        lblRoleSx91: {
            keyCode: 'SX91',
            labelValue: 'Pension check recipient'
        },
        lblRoleSx92: {
            keyCode: 'SX92',
            labelValue: 'Trade advice recipient'
        },
        lblRoleTral: {
            keyCode: 'TRAL',
            labelValue: 'Trustee alias'
        },
        lblRoleTrre: {
            keyCode: 'TRRE',
            labelValue: 'Trustee receiver'
        },
        lblRoleTrtr: {
            keyCode: 'TRTR',
            labelValue: 'Trustee trustee'
        },
        lblRoleUser: {
            keyCode: 'USER',
            labelValue: 'IIO firm user'
        }
    },
    technical_difficulties_API_error: {
        heading: 'Give us a call',
        subheading: 'We are experiencing technical difficulties.',
        navReturnProfileAccountSettings: 'Return to profile & account settings'
    },
    teamMFECloseButton: 'Close'
}